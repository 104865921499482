import { ref, onMounted } from 'vue';
import { Sidebar, SidebarItem } from 'vant';
import { useWindowSize, useRect } from '@vant/use';
export default {
  setup() {
    // 导航条 - 返回
    const onClickLeft = () => history.back();
    // Tabbar 标签栏 - 选中
    const active = ref('shop-class');
    // 获取浏览器窗口的视口宽度和高度
    const {
      width,
      height
    } = useWindowSize();
    const ijheight = height;
    const barheight = ref();
    onMounted(() => {
      const rect = useRect(barheight);
      console.log(rect);
    });
    return {
      onClickLeft,
      active,
      barheight
    };
  }
};